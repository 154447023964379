<template>
<v-container>
    <p class="text-h4">So gehen wir mit deinen Daten um</p>
    <p class="text-body-1">
      <a href="http://iam.dioe.at/" target="_blank"
        >IamDiÖ – Erforsche Deutsch in Österreich!</a
      >
      ist als Teil des
      <a href="http://dioe.at/" target="_blank"
        >SFBs „Deutsch in Österreich. Variation – Kontakt – Perzeption“</a
      >
      darum bemüht, sämtliche datenschutzrechtlichen Aspekte zu
      berücksichtigen.<span class="font-weight-bold">
        Es werden keine Daten von Teilnehmenden und Besuchenden, die wir als
        Mitforschende verstehen, ohne ihr Einverständnis veröffentlicht!</span
      >
    </p>
    <p class="text-body-1">
      Wir verpflichten uns, deine Privatsphäre zu schützen und wollen so allen
      Mitforschenden eine sichere und funktionelle Forschungsumgebung bieten.
      Diese Datenschutzerklärung gilt für die IamDiÖ-Webseiten und regelt die
      Erhebung und Verwendung von Daten durch unsere Mitmachaktionen (on- und
      offline). Sie ist eine Erweiterung der allgemeinen Datenschutzerklärung
      des Projekts (siehe unten).
    </p>
    <p class="text-h5">Sammeln von persönlichen Daten bei Mitmachaktionen</p>
    <p class="text-body-1">
      Beim Einsenden von bestimmten Beiträgen über unsere Webseiten, aber auch
      bei Mitmachaktionen auf Wissenschaftsfestivals, wirst du gebeten,
      persönliche Informationen an uns zu übermitteln bzw. uns mitzuteilen. Wir
      geben keine dieser Daten ohne deine schriftliche Zustimmung an Dritte
      weiter (d.h. Personen, die nicht bei IamDiÖ oder beim SFB DiÖ arbeiten),
      es sei denn es wird uns gesetzlich vorgeschrieben.
    </p>
    <p class="text-body-1">
      Wir nutzen deine persönlichen Daten zum einen für die Kommunikation mit
      dir, wenn du den Kontakt ausdrücklich wünschst (z.B. für die Zusendung von
      Forschungsergebnissen, weiteren Informationen etc.), zum anderen werden
      deine Daten wissenschaftlich ausgewertet und im Zuge dessen innerhalb des
      SFBs, des IamDiÖ-Projekts sowie von IamDiÖ-Kooperationspartner*innen zu
      Forschungszwecken verwendet.
    </p>
    <p class="text-body-1">
      Damit deine Beiträge wissenschaftlich verwendet und ausgewertet werden
      dürfen, musst du uns dein Einverständnis dafür geben. Nur so kannst du mit
      deinen Beiträgen unsere Forschung, aber auch die der anderen
      Mitforschenden, unterstützen. Beiträge, die ohne unterzeichnete
      Einverständniserklärung abgegeben werden, können von uns nicht vollständig
      ausgewertet und daher auch nur begrenzt für Forschungszwecke verwendet
      werden.
    </p>
    <p class="text-body-1">
      Bei folgenden Mitmachaktionen musst du (damit du mitmachen kannst oder die
      App funktioniert) vorab dein Einverständnis geben und die
      Nutzungsbedingungen akzeptieren.
    </p>
    <p class="text-body-1">
      Frage des Monats, Schnitzeljagd nach Schrift im öffentlichen Raum,
      Meme-Contest, Varietäten Raten, Wörtersammeln im Lexik-Tool „Wortgut“.
    </p>

    <p class="text-h5">Veröffentlichung von Forschungsdaten und -ergebnissen</p>

    <p class="text-body-1">Für on- und offline eingereichte Beiträge gilt:</p>
    <p class="text-body-1">
      Gewonnene Forschungsdaten und Metadaten werden, sofern keine rechtlichen
      oder ethischen Gründe dagegensprechen, auf der Forschungsplattform des
      <a href="http://dioe.at/" target="_blank">Hauptprojekts</a> zur Verfügung
      gestellt. Die Forschungsplattform wird über die Website
      <a href="http://dioe.at/" target="_blank">dioe.at</a> in Zukunft
      zugänglich sein. Persönliche Daten wie der Name werden nur mit Zustimmung
      veröffentlicht, andernfalls stehen die gesammelten Forschungsdaten nur
      anonymisiert der Öffentlichkeit zur Verfügung.
    </p>
    <p class="text-body-1">
      Desweitern werden, sofern keine rechtlichen oder ethischen Gründe
      dagegensprechen, alle Ergebnisse (Frage des Monats und deren Antworten,
      Ergebnisberichte zu Aktionen, etc.) auf unserer Projektwebsite sowie auf
      unseren Social-Media-Kanälen (Facebook, Twitter, Blog) öffentlich
      zugänglich gemacht. Wissenschaftliche Publikationen sind soweit wie
      möglich Open Access.
    </p>
    <p class="text-h5">Forschungethische Grundsätze</p>
    <p class="text-body-1">
      IamDiÖ verpflichtet sich zur Einhaltung forschungsethischer Grundsätze:
    </p>
    <p class="text-body-1">
      Bei Mitmachaktionen werden keine Informationen über die teilnehmenden
      Personen ohne deren Einwilligung preisgegeben.
    </p>
    <p class="text-body-1">
      Es werden in unserem Projekt keine Beiträge zugelassen, die beleidigend,
      diffamierend oder strafrechtlich relevant sind, sexistische, rassistische
      oder diskriminierende Inhalte haben, Androhungen oder Aufforderungen zu
      Gewalt enthalten, die durch ihre Inhalte (insbesondere Text, Bilder,
      Fotografien, Zeichnungen, Videos) Urheberrechte, Herstellerrechte,
      Werknut-zungsrechte oder Persönlichkeitsrechte Dritter verletzen sowie
      persönliche Angriffe oder Untergriffigkeiten enthalten bzw. nur zum Ziel
      haben, eine Person, eine Personengruppe oder eine Region schlecht zu
      machen.
    </p>
    <p class="text-h4">Allgemeine Datenschutzerklärung</p>

    <p class="text-body-1">
      Wir haben diese Datenschutzerklärung verfasst, um Ihnen gemäß der Vorgaben
      der EU-Datenschutz-Grundverordnung (DSGVO) zu erklären, welche
      Informationen wir sammeln, wie wir Daten verwenden und welche
      Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
    </p>
    <p class="text-body-1">
      Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr
      technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die
      wichtigsten Dinge so einfach und klar zu beschreiben.
    </p>
    <p class="text-h4">Allgemeine Datenspeicherung</p>
    <p class="text-body-1">
      Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen
      automatisch erstellt und gespeichert, so auch auf dieser Webseite.
    </p>
    <p class="text-body-1">
      Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser
      Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch
      Daten wie die IP-Adresse Ihres Gerätes, die Adressen der besuchten
      Unterseiten, Details zu Ihrem Browser (z.B. Chrome, Firefox, Edge,…) und
      Datum sowie Uhrzeit. Wir nutzen diese Daten nicht und geben Sie in der
      Regel nicht weiter, können jedoch nicht ausschließen, dass diese Daten
      beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
    </p>
    <p class="text-h4">Speicherung persönlicher Daten</p>
    <p class="text-body-1">
      Persönliche Daten, die Sie uns auf dieser Website elektronisch
      übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere
      persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder
      Kommentaren, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse
      nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an
      Dritte weitergegeben.
    </p>
    <p class="text-body-1">
      Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit
      jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung
      der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir
      geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch
      nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem
      Verhalten eingesehen werden.
    </p>
    <p class="text-body-1">
      Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser
      Webseite – können wir keine sichere Übertragung und den Schutz Ihrer Daten
      garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals
      unverschlüsselt per E-Mail zu übermitteln.
    </p>
    <p class="text-h4">Ihre Rechte</p>

    <p class="text-body-1">
      Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung,
      Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch
      zu.
    </p>
    <p class="text-body-1">
      Unter den im
      <router-link to="/imprint"> Impressum</router-link> angegebenen
      Kontaktdaten, können Sie diese Rechte ausüben.
    </p>
    <p class="text-body-1">
      Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
      Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst
      in einer Weise verletzt worden sind, können Sie sich bei der
      Aufsichtsbehörde beschweren. In Österreich ist dies die
      Datenschutzbehörde, deren Webseiten Sie unter
      <a href="https://www.dsb.gv.at/" target="_blank">
        https://www.dsb.gv.at/</a
      >
      finden.
    </p>
    <p class="text-h4">Ihre Rechte</p>

    <p class="text-body-1">
      Wir verwenden https (Hypertext Transfer Protocol Secure) um Daten
      abhörsicher im Internet zu übertragen. Durch den Einsatz von TLS
      (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren
      Datenübertragung im Internet, können wir den Schutz vertraulicher Daten
      sicherstellen. Sie erkennen die Benutzung dieser Absicherung der
      Datenübertragung am kleinen Schloßsymbol links oben im Browser und der
      Verwendung des Schemas https als Teil unserer Internetadresse.
    </p>
    <p class="text-body-1"></p>
    <p class="text-h4">Cookies</p>
    <p class="text-body-1">
      Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu
      speichern.
    </p>
    <p class="text-body-1">
      Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu
      speichern.
    </p>
    <p class="text-body-1">
      Es gibt zwei Arten von Cookies: Erstanbieter-Cookies werden von unserer
      Website erstellt, Drittanbieter-Cookies werden von anderen Websites (z. B.
      Google Analytics) erstellt.
    </p>
    <p class="text-body-1">
      Man unterscheidet drei Kategorien von Cookies: unbedingt notwendige
      Cookies um grundlegende Funktionen der Website sicherzustellen,
      funktionelle Cookies um die Leistung der Webseite sicherzustellen und
      zielorientierte Cookies um das Benutzererlebnis zu verbessern.
    </p>
    <p class="text-body-1">
      Wir nutzen Cookies, um unsere Webseite nutzerfreundlicher zu gestalten.
      Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese
      löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch
      wiederzuerkennen.
    </p>
    <p class="text-body-1">
      Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten,
      dass er Sie über das Setzen von Cookies informiert und Sie dies nur im
      Einzelfall erlauben. Sie können jederzeit Cookies, die sich bereits auf
      Ihrem Computer befinden, löschen oder Cookies deaktivieren. Die
      Vorgangsweise dazu ist nach Browser unterschiedlich, am besten Sie suchen
      die Anleitung in einer Websuchmaschine mit dem Suchbegriff „cookies
      löschen chrome“ oder „cookies deaktivieren chrome“ im Falle eines Chrome
      Browsers oder tauschen das Wort „chrome“ gegen den Namen Ihres Browsers,
      z. B. edge, firefox, safari aus.
    </p>
    <p class="text-body-1">
      Wenn Sie uns generell nicht gestatten, Cookies zu nutzen, d.h. diese per
      Browsereinstellung deaktivieren, können manche Funktionen und Seiten nicht
      wie erwartet funktionieren.
    </p>
    <p class="text-h4">Google Analytics Datenschutzerklärung</p>

    <p class="text-body-1">
      Wir verwenden auf dieser Webseite Google Analytics der Firma Google Inc.
      (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) um Besucherdaten
      statistisch auszuwerten. Dabei verwendet Google Analytics zielorientierte
      Cookies.
    </p>
    <p class="text-body-1">
      Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie
      unter
      <a href="http://www.google.com/analytics/terms/de.html" target="_blank">
        http://www.google.com/analytics/terms/de.html</a
      >
      bzw. unter
      <a
        href="https://support.google.com/analytics/answer/6004245?hl=de"
        target="_blank"
      >
        https://support.google.com/analytics/answer/6004245?hl=de</a
      >.
    </p>
    <p class="text-h5">Pseudonymisierung</p>

    <p class="text-body-1">
      Unser Anliegen im Sinne der DSGVO ist die Verbesserung unseres Angebotes
      und unseres Webauftritts. Da uns die Privatsphäre unserer Nutzer wichtig
      ist, werden die Nutzerdaten pseudonymisiert. Die Datenverarbeitung erfolgt
      auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des Art 6
      EU-DSGVO Abs 1 lit a (Einwilligung) und/oder f (berechtigtes Interesse)
      der DSGVO.
    </p>

    <p class="text-h5">
      Deaktivierung der Datenerfassung durch Google Analytics
    </p>

    <p class="text-body-1">
      Mithilfe des Browser-Add-ons zur Deaktivierung von Google
      Analytics-JavaScript (ga.js, analytics.js, dc.js) können Website-Besucher
      verhindern, dass Google Analytics ihre Daten verwendet.
    </p>
    <p class="text-body-1">
      Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre
      Nutzung der Website bezogenen Daten an Google sowie die Verarbeitung
      dieser Daten durch Google verhindern, indem Sie das unter dem folgenden
      Link verfügbare Browser-Plugin herunterladen und installieren:
      <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
        >https://tools.google.com/dlpage/gaoptout?hl=de</a
      >.
    </p>

    <p class="text-h5">Google Analytics Zusatz zur Datenverarbeitung</p>

    <p class="text-body-1">
      Wir haben mit Google einen Direktkundenvertrag zur Verwendung von Google
      Analytics abgeschlossen, indem wir den “Zusatz zur Datenverarbeitung“ in
      Google Analytics akzeptiert haben.
    </p>
    <p class="text-body-1">
      Mehr über den Zusatz zur Datenverarbeitung für Google Analytics finden Sie
      hier:<a
        hreaf="https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad"
        target="_blank"
      >
        https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad</a
      >.
    </p>
    <p class="text-h5">
      Google Analytics Berichte zu demografischen Merkmalen und Interessen
    </p>

    <p class="text-body-1">
      Wir haben in Google Analytics die Funktionen für Werbeberichte
      eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen
      enthalten Angaben zu Alter, Geschlecht und Interessen. Damit können wir
      uns – ohne diese Daten einzelnen Personen zuordnen zu können – ein
      besseres Bild von unseren Nutzern machen. Mehr über die Werbefunktionen
      erfahren Sie auf
      <a
        href="https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad"
        target="_blank"
        >https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad</a
      >.
    </p>
    <p class="text-body-1">
      Sie können die Nutzung der Aktivitäten und Informationen Ihres Google
      Kontos unter “Einstellungen für Werbung“ auf
      <a href="https://adssettings.google.com/authenticatedpe" target="_blank"
        >https://adssettings.google.com/authenticatedper</a
      >
      Checkbox beenden.
    </p>

    <p class="text-h5">Google Fonts Datenschutzerklärung</p>

    <p class="text-body-1">
      Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre
      Parkway Mountain View, CA 94043, USA) auf unserer Webseite. Die Verwendung
      von Google Fonts erfolgt ohne Authentisierung und es werden keine Cookies
      and die Google Fonts API gesendet. Sollten Sie ein Konto bei Google haben,
      werden keine Ihrer Google-Kontodaten an Google während der Nutzung von
      Google Fonts übermittelt. Google erfasst lediglich die Nutzung von CSS und
      der verwendeten Fonts und speichert diese Daten sicher. Mehr zu diesen und
      weiteren Fragen finden Sie auf <a href="https://developers.google.com/fonts/faq" target="_blank">https://developers.google.com/fonts/faq</a>.
    </p>
    <p class="text-body-1">
      Welche Daten von Google erfasst werden und wofür diese Daten verwendet
      werden, können Sie auf
      <a href="https://www.google.com/intl/de/policies/privacy/" target="_blank">https://www.google.com/intl/de/policies/privacy/</a> nachlesen.
    </p>
    <p class="text-body-1">
      Quelle: Erstellt mit dem <a href="https://www.firmenwebseiten.at/datenschutz-generator/" target="_blank">Datenschutz Generator von firmenwebseiten.at</a> in
      Kooperation mit <a href="https://www.startiness.at/" target="_blank">startiness.at</a>
    </p>
</v-container>
</template>

<script>
export default {
    
}
</script>
<style >

</style>